import React, { useState } from "react";
import emailjs from "emailjs-com";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
	const { t } = useTranslation();

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID,
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
				e.target,
				process.env.REACT_APP_EMAILJS_USER_ID
			)
			.then(
				(result) => {
					alert(t("contactUs.successMessage"));
					setFormData({
						name: "",
						email: "",
						phone: "",
						message: "",
					});
				},
				(error) => {
					alert(t("contactUs.failureMessage"));
				}
			);
	};

	return (
		<>
			<div className="bg-gray-100 dark:bg-gray-900 dark:text-white py-12">
				<div className="container mx-auto px-6 md:px-12">
					<h1 className="text-3xl md:text-5xl font-bold text-center mb-8">
						{t("contactUs.title")}
					</h1>
					<div className="flex justify-center">
						<form
							onSubmit={handleSubmit}
							className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg"
						>
							<div className="mb-6">
								<label
									htmlFor="name"
									className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
								>
									{t("contactUs.nameLabel")}
								</label>
								<input
									type="text"
									name="name"
									id="name"
									value={formData.name}
									onChange={handleChange}
									required
									className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
								/>
							</div>
							<div className="mb-6">
								<label
									htmlFor="email"
									className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
								>
									{t("contactUs.emailLabel")}
								</label>
								<input
									type="email"
									name="email"
									id="email"
									value={formData.email}
									onChange={handleChange}
									required
									className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
								/>
							</div>
							<div className="mb-6">
								<label
									htmlFor="phone"
									className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
								>
									{t("contactUs.phoneLabel")} (
									{t("contactUs.optionalLabel")})
								</label>
								<input
									type="tel"
									name="phone"
									id="phone"
									value={formData.phone}
									onChange={handleChange}
									className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
								/>
							</div>
							<div className="mb-6">
								<label
									htmlFor="message"
									className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
								>
									{t("contactUs.messageLabel")}
								</label>
								<textarea
									name="message"
									id="message"
									value={formData.message}
									onChange={handleChange}
									required
									className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
									rows="5"
								></textarea>
							</div>
							<div className="flex justify-center">
								<button
									type="submit"
									className="bg-primary text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
								>
									{t("contactUs.submitButton")}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ContactUs;
