import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero from "../components/Hero/Hero";
import AboutUs from "../components/AboutUs/AboutUs";
import Services from "../components/Services/Services";
import BlogsComp from "../components/Blogs/BlogsComp";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const elementId = hash.substring(1);
			const element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [hash]);

	return (
		<>
			<Hero />
			<Services />
			<AboutUs />
			<BlogsComp />
			<Footer />
		</>
	);
};

export default HomePage;
