import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/Homepage";
import Navbar from "../components/Navbar/Navbar";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";

const RouterManagement = () => {
	return (
		<BrowserRouter>
			<div className="overflow-x-hidden">
				<Navbar />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/about-us" element={<AboutUs />} />
				</Routes>
			</div>
		</BrowserRouter>
	);
};

export default RouterManagement;
