import React from "react";
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-dark text-white py-6">
			<section className="container flex flex-col items-center md:flex-row md:justify-between space-y-4 md:space-y-0 md:space-x-6 px-6">
				{/* Title and Description */}
				<div className="flex flex-col items-center md:flex-row md:items-center md:space-x-4 text-center md:text-left w-full md:w-auto">
					<h1 className="text-xl font-bold whitespace-nowrap">
						{t("footer.business")}
					</h1>
					<p className="text-sm text-gray-400 mt-2 md:mt-0 md:flex-grow">
						{t("footer.businessDescription")}
					</p>
				</div>

				{/* Social Links */}
				<div className="flex gap-6 justify-center">
					<a
						href="https://www.instagram.com/kostas_premtis/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaInstagram className="text-2xl hover:text-primary duration-300" />
					</a>
					<a
						href="https://www.facebook.com/kwstas.okirilatos"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaFacebook className="text-2xl hover:text-primary duration-300" />
					</a>
					<a href="mailto:premtisk@gmail.com">
						<FaEnvelope className="text-2xl hover:text-primary duration-300" />
					</a>
				</div>
			</section>
		</div>
	);
};

export default Footer;
