import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translations
import enTranslation from "./locales/en.json";
import elTranslation from "./locales/el.json";

const resources = {
	en: {
		translation: enTranslation,
	},
	el: {
		translation: elTranslation,
	},
	// Add more languages here
};

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		debug: true,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
