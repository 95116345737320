import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AboutUs = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleAboutUsClick = () => {
		navigate("/about-us");
	};

	return (
		<div
			id="about"
			className="bg-gray-100 dark:bg-gray-900 dark:text-white py-12"
		>
			<div className="container mx-auto px-6 md:px-12">
				<h1 className="text-3xl md:text-5xl font-bold text-center mb-8">
					{t("about.title")}
				</h1>
				<div className="flex flex-col md:flex-row items-center md:items-start">
					<div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
						<Carousel
							autoPlay
							infiniteLoop
							showThumbs={false}
							showIndicators={false}
							showStatus={false}
							className="w-full"
						>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/a8ot1jkfbwycwh5ibbkq"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/b4gtjnk3uxwk61th29ss"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/mtrpgcit3yd4nsxzhg29"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/venlvjfuskcgl5z4nqzn"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/co9egnxtsuwsmmjnyrrh"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/a17pgghc0hofakj5hmuw"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/i7udhmb0syhodwqusevt"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/yshfnsrqcwpvs8s83qza"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/to6vvtho3ue2xypj9sr0"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/xvqmoksinn4u8cldqbon"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
							<div>
								<img
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/f_auto,q_auto/v1/AccountingBusinessCDN/m4eowmwtw76s25yqxtsq"
									alt={t("about.officeAlt")}
									className="rounded-lg object-cover w-full h-96"
								/>
							</div>
						</Carousel>
					</div>
					<div className="md:w-1/2 md:pl-12">
						<h2 className="text-2xl font-semibold mb-4">
							{t("about.welcome")}
						</h2>
						<p className="mb-4">{t("about.description")}</p>
						<h3 className="text-xl font-semibold mb-2">
							{t("about.founderTitle")}
						</h3>
						<p className="mb-4">{t("about.founderDescription")}</p>
					</div>
				</div>
				<div className="text-center mt-4 sm:mt-8">
					<button
						onClick={handleAboutUsClick}
						className="primary-btn"
					>
						{t("services.learnMore")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
