import React, { useState, useEffect } from "react";
import { BiSolidMoon, BiSolidSun } from "react-icons/bi";

const DarkMode = () => {
	// Initialize theme state from localStorage or default to "light"
	const [theme, setTheme] = useState(
		localStorage.getItem("theme") || "light"
	);

	useEffect(() => {
		// Apply the theme to the document
		const element = document.documentElement;

		if (theme === "dark") {
			element.classList.add("dark");
		} else {
			element.classList.remove("dark");
		}

		// Store the theme preference in localStorage
		localStorage.setItem("theme", theme);
	}, [theme]); // Dependency array ensures this effect runs when `theme` changes

	return (
		<div>
			{theme === "dark" ? (
				<BiSolidSun
					onClick={() => setTheme("light")}
					className="text-2xl cursor-pointer"
					title="Switch to light mode"
				/>
			) : (
				<BiSolidMoon
					onClick={() => setTheme("dark")}
					className="text-2xl cursor-pointer"
					title="Switch to dark mode"
				/>
			)}
		</div>
	);
};

export default DarkMode;
