import React from "react";
import { MenuLinks } from "./Navbar";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../config/translation/LanguageSwitcher";
const ResponsiveMenu = ({ showMenu, closeMenu }) => {
	const { t } = useTranslation();

	return (
		<div
			className={`${
				showMenu ? "left-0" : "left-[-100%]"
			} fixed bottom-0 top-0 w-[75%] transition-all duration-300 pt-16 px-8 bg-test dark:bg-gray-900 z-50 shadow-md flex flex-col justify-between pb-4`}
		>
			<div className="absolute top-4 right-4 z-10">
				<LanguageSwitcher />
			</div>
			<div className="card">
				<nav className="mt-2">
					<ul className="space-y-4 text-xl">
						{MenuLinks.map(({ id, name, link }) => (
							<li key={id}>
								<a
									href={"/" + link}
									className="mb-5 inline-block"
									onClick={closeMenu}
								>
									{t(name)}
								</a>
							</li>
						))}
					</ul>
				</nav>
			</div>

			<div>
				<h1>All rights reserved &copy; {new Date().getFullYear()}</h1>
			</div>
		</div>
	);
};

export default ResponsiveMenu;
