import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogCard from "./BlogCard";
import { useTranslation } from "react-i18next";

const BlogsComp = () => {
	const [articles, setArticles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setError(null);

			try {
				const language = localStorage.getItem("language") || "en";
				const response = await axios.get(
					"https://gnews.io/api/v4/top-headlines",
					{
						params: {
							topic: "business",
							token: process.env.REACT_APP_GNEWS_API_KEY,
							max: 3,
							lang: language,
						},
					}
				);
				setArticles(response.data.articles);
			} catch (error) {
				setError("Failed to load articles. Please try again later.");
				console.error("Error fetching articles:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [i18n.language]);

	if (loading) {
		return <p>Loading...</p>;
	}

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div
			id="blogs"
			className="bg-gray-100 dark:bg-gray-900 dark:text-white py-10 pb-14"
		>
			<section data-aos="fade-up" className="container">
				<h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-semibold">
					{t("bussinessNews")}
				</h1>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{articles.map((article, index) => (
						<BlogCard
							key={index}
							image={article.image}
							title={article.title}
							description={article.description}
							author={article.source.name}
							date={article.publishedAt}
							url={article.url}
						/>
					))}
				</div>
			</section>
		</div>
	);
};

export default BlogsComp;
