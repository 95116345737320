import React from "react";
import DarkMode from "./DarkMode";
import ResponsiveMenu from "./ResponsiveMenu";
import { HiMenuAlt3, HiMenuAlt1 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../config/translation/LanguageSwitcher";

export const MenuLinks = [
	{
		id: 1,
		name: "navbar.home",
		link: "#hero",
	},
	{
		id: 2,
		name: "navbar.services",
		link: "#services",
	},
	{
		id: 3,
		name: "navbar.about",
		link: "#about",
	},
	{
		id: 4,
		name: "navbar.blogs",
		link: "#blogs",
	},
];

const Navbar = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleContactClick = () => {
		navigate("/contact-us");
	};
	const [showMenu, setShowMenu] = React.useState(false);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const closeMenu = () => {
		setShowMenu(false);
	};
	const handleNavigation = (link) => {
		navigate("/"); // Navigate to the homepage
		setTimeout(() => {
			const section = document.getElementById(link);
			if (section) {
				section.scrollIntoView({ behavior: "smooth" });
			}
		}, 200);
	};

	return (
		<>
			<nav className="bg-test dark:bg-black dark:text-white duration-100">
				<div className="container py-3 md:py-2">
					<div className="flex justify-between items-center">
						<div className="">
							<a className="flex items-center gap-3" href="/">
								<img
									className="w-12"
									src="https://res.cloudinary.com/dmwvkjqsn/image/upload/v1715788931/AccountingBusinessCDN/drwj6o0fn7chiqrlstdg.jpg"
									alt={t("navbar.logoAlt")}
								/>
								<span className="text-xl sm:text-xl font-semibold">
									{t("navbar.company")}
								</span>
							</a>
						</div>

						<div className="hidden md:block">
							<ul className="flex items-center gap-8">
								{MenuLinks.map(({ id, name, link }) => (
									<li
										key={id}
										className="cursor-pointer py-4"
									>
										<a
											href={"/" + link}
											onClick={() =>
												handleNavigation(link)
											}
											className="text-lg font-medium hover:text-primary hover:border-b-2 hover:border-primary transition-all duration-300"
										>
											{t(name)}
										</a>
									</li>
								))}
								<button
									onClick={handleContactClick}
									className="primary-btn"
								>
									{t("navbar.contact")}
								</button>
								<LanguageSwitcher />
								<DarkMode />
							</ul>
						</div>

						<div className="flex items-center gap-4 md:hidden">
							<DarkMode />
							{showMenu ? (
								<HiMenuAlt1
									onClick={toggleMenu}
									className="cursor-pointer text-2xl"
								/>
							) : (
								<HiMenuAlt3
									onClick={toggleMenu}
									className="cursor-pointer text-2xl"
								/>
							)}
						</div>
					</div>
				</div>
				<ResponsiveMenu showMenu={showMenu} closeMenu={closeMenu} />
			</nav>
		</>
	);
};

export default Navbar;
