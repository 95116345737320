import React from "react";
import { FaReceipt, FaPassport, FaHome, FaAnchor } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const servicesData = [
	{
		name: "services.accounting",
		icon: <FaReceipt className="text-4xl text-primary" />,
		description: "services.accountingDesc",
	},
	{
		name: "services.tax",
		icon: <FaAnchor className="text-4xl text-primary" />,
		description: [
			"services.taxDesc1",
			"services.taxDesc2",
			"services.taxDesc3",
			"services.taxDesc4",
		],
	},
	{
		name: "services.financialManagement",
		icon: <FaHome className="text-4xl text-primary" />,
		description: "services.financialManagementDesc",
	},
	{
		name: "services.consulting",
		icon: <FaPassport className="text-4xl text-primary" />,
		description: [
			"services.consultingDesc1",
			"services.consultingDesc2",
			"services.consultingDesc3",
			"services.consultingDesc4",
			"services.consultingDesc5",
			"services.consultingDesc6",
		],
	},
];

const Services = () => {
	const { t } = useTranslation();

	return (
		<div
			id="services"
			className="bg-gray-200 dark:bg-black dark:text-white py-12 sm:grid:place-items-center"
		>
			<div className="container">
				<div className="pb-12 text-center space-y-3">
					<h1 className="text-3xl font-semibold text-primary dark:text-primary">
						{t("services.explore")}
					</h1>
					<p>{t("services.intro")}</p>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
					{servicesData.map((service) => (
						<div
							key={service.name}
							className="card space-y-3 sm:space-y-4 p-4"
						>
							<div>{service.icon}</div>
							<h1 className="text-xl font-semibold">
								{t(service.name)}
							</h1>

							{Array.isArray(service.description) ? (
								<ul className="list-disc pl-5 text-gray-600 dark:text-gray-400 space-y-1">
									{service.description.map((desc, index) => (
										<li key={index}>{t(desc)}</li>
									))}
								</ul>
							) : (
								<p className="text-gray-600 dark:text-gray-400">
									{t(service.description)}
								</p>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Services;
