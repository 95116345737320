import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Import flag images (example paths, replace with actual paths)
import enFlag from "../../assets/flags/en.svg";
import elFlag from "../../assets/flags/el.svg";

const languageOptions = [
	{
		value: "en",
		label: "English",
		flag: enFlag,
	},
	{
		value: "el",
		label: "Ελληνικά",
		flag: elFlag,
	},
];

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const storedLanguage = localStorage.getItem("language");
		const defaultLanguage = storedLanguage || "el"; // Default to Greek if no language is set
		if (i18n.language !== defaultLanguage) {
			i18n.changeLanguage(defaultLanguage);
		}
	}, [i18n]);

	const handleLanguageChange = (language) => {
		i18n.changeLanguage(language);
		localStorage.setItem("language", language);
		setIsOpen(false);
	};

	const currentLanguage = i18n.language;

	return (
		<div className="relative">
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="flex items-center gap-2 p-2 border border-gray-300 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-600 dark:text-white"
			>
				<img
					src={
						languageOptions.find(
							(option) => option.value === currentLanguage
						)?.flag
					}
					alt="Language Flag"
					className="w-6 h-4"
				/>
				<span>
					{
						languageOptions.find(
							(option) => option.value === currentLanguage
						)?.label
					}
				</span>
			</button>

			{isOpen && (
				<div className="absolute right-0 mt-2 w-32 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
					{languageOptions.map(({ value, label, flag }) => (
						<button
							key={value}
							onClick={() => handleLanguageChange(value)}
							className="flex items-center p-2 w-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
						>
							<img
								src={flag}
								alt={`${label} Flag`}
								className="w-6 h-4 mr-2"
							/>
							{label}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default LanguageSwitcher;
